import axios from "axios";
import {
  reqExpost,
  ajaxGet,
  ajaxPost,
  
  uploadImg,
} from "./request";
import { url } from "./url";



//管理员相关
export const loadCaptchaApi = (params={}) =>ajaxGet({url:'load/captcha', params})
export const adminLoginApi = (params={}) =>ajaxPost({url:'admin/login', params})
export const loadAdminMenusApi = (params={}) =>ajaxPost({url:'admin/menus', params})

export const loadAdminListApi = (params={}) =>ajaxGet({url:'sys/admin/list', params})

export const addAdminApi = (params={}) =>ajaxPost({url:'sys/admin/add', params})
export const updateAdminApi = (params={}) =>ajaxPost({url:'sys/admin/update', params})

export const upateAdminRoleApi = (params={}) =>ajaxPost({url:'sys/admin/update/role', params})

export const resetAdminPwdApi = (params={}) =>ajaxPost({url:'sys/admin/reset/password', params})

export const manageAdminStatusApi = (params={}) =>ajaxPost({url:'sys/admin/manage/status', params})

export const updateAdminOwnPwsApi = (params={}) =>ajaxPost({url:'sys/admin/update/own/password', params})

//角色相关
export const loadRoleListApi = (params={}) =>ajaxGet({url:'sys/role/list', params})

export const loadRoleMenusApi = (params={}) =>ajaxGet({url:'sys/role/menus', params})
export const saveRoleMenusApi = (params={}) =>ajaxPost({url:'sys/role/save/menus', params})


export const upateRoleApi = (params={}) =>ajaxPost({url:'sys/role/update', params})

export const manageRoleStatusApi = (params={}) =>ajaxPost({url:'sys/role/manage/status', params})

//菜单相关
export const loadMenuListApi = (params={}) =>ajaxGet({url:'sys/menu/list', params})

export const loadMenuTreeApi = (params={}) =>ajaxGet({url:'sys/menu/tree', params})
export const addMenuApi = (params={}) =>ajaxGet({url:'sys/menu/add', params})
export const editMenuApi = (params={}) =>ajaxGet({url:'sys/menu/edit', params})
export const loadMenuButtonsApi = (params={}) =>ajaxGet({url:'sys/menu/buttons', params})

export const manageMenuStatusApi = (params={}) =>ajaxPost({url:'sys/menu/manage/status', params})
